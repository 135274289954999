<template>
  <div class="mobileUsbParam">
    <mHead :name="'五孔带USB电源面板'" :add="'/mobile/ProUsb'" :adds="'/mobile/ProUsbParam'" :color="false" />
    <div class="param">
      <div class="title">相关参数</div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">符合86盒标准：86×86mm</div>
      </div>
      <div class="text">
        <div class="left">额定输出:</div>
        <div class="right">AC220V/10A</div>
      </div>
      <div class="text">
        <div class="left">USB输出:</div>
        <div class="right">2×USB/2.1A</div>
      </div>

      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
    </div>
    <div class="paramDe">
      <div class="txt">五孔带USB电源面板可同时提供AC220V和DC5V两种电源，方便客人的各种电子设备取电。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.mobileUsbParam {
  background-color: #fff;
  .param {
    margin: 0px 0.3rem;
    padding-bottom: 0.26rem;
    border-bottom: 0.01rem solid #333;
    .title {
      margin-top: 0.8rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: bold;
      color: #ff9000;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0.6rem;
      }
      .right {
        margin-left: 0.59rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0px 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.19rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>
